<template>
  <div class="index">
    <van-nav-bar
      title="待跟进客户"
      left-text="返回"
      left-arrow
      fixed
      placeholder
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="content">
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" style="margin-bottom: 10px">
        请补充以下客户的意向等级\意向车型\跟进记录
      </van-notice-bar>
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell-group inset  :border="false" v-for="(item, index) in list" :key="index" class="list-item" @click="pushUserEmployee(item.contact_id, item.employee_id)">
          <van-cell is-link :border="false">
            <template #title>
              <span class="custom-title list-item-title">
                <van-image
                  width="44"
                  height="44"
                  fit="cover"
                  round
                  :src="item.avatar"
                />
                <div>
                  {{ item.name }}
                  <div class="time"><van-icon name="notes-o" /> {{ item.created_at }}</div>
                </div>
              </span>
            </template>
            <template>
              <span class="list-item-value">跟进</span>
            </template>
          </van-cell>
          <!-- <van-cell>
            <template #title>
              <span class="custom-title list-item-label" style="margin-right: 10px">客户总人数/已跟进人数</span>
            </template>
            <template>
              <span class="list-item-value">
                <van-icon name="friends-o" /> {{ item.total_contact }} / <van-tag plain type="primary"> {{ item.contacted }} </van-tag>
              </span>
            </template>
          </van-cell> -->
        </van-cell-group>
        <!-- <div class="list-item" v-for="(item, index) in list" :key="index">
          
        </div> -->
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('../../assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
  </div>
</template>
<script>

import { pushUserEmployee } from '@/plugins/utils'
import { ContactFollowApi } from '@/api/contactFollow'

export default {
  data() {
    return {
      pushUserEmployee,
      serchValue: '',
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15,
    }
  },
  created () {
    this.employee_id = this.$route.query.employee_id
    window.scrollTo(0, 0)
  },
 
  methods: {
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      ContactFollowApi.waitList({
        employee_id: this.employee_id,
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.lists.data || [])
      })
    },
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  .content {

    /deep/.van-cell-group {
      margin: 0;
    }
    // background: #f4f5f7;
    padding: 12px 10px;

    .list-item {
      margin-bottom: 8px;
      // background: #fff;
      // box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);
      // border: 1px solid #F1f2f5;

      /deep/.van-cell {
        align-items: center;
        background-color: #FBFBFB;
        padding: 10px;

        .van-cell__value {
          flex: unset;
        }
      }
      
      .time {
        font-size: 12px;
        color: #888;
        line-height: 18px;
      }

      &-title {
        display: flex;
        align-items: center;

        .van-image {
          margin-right: 8px;
        }
      }

      &-label {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
      }

      &-value {
        font-size: 12px;
        color: #1989fa;
      }

      /deep/.van-cell__right-icon {
        // vertical-align: bottom;
        // font-size: 12px;
        height: auto;
        color: #ddd;
      }
    }      
  }
}
</style>
